
import { Component, Vue } from 'vue-property-decorator'
import { KeepAlive } from '@/utils/decorators'
@Component({
  name: 'RareTreesBase'
})
@KeepAlive
export default class RareTreesBase extends Vue {
    activeName = 'list'

    created () {
      this.activeName = this.$route.path.substr(this.$route.path.lastIndexOf('/') + 1)
      this.goPage(this.activeName)
    }

    goPage (value: string) {
      if (value === 'map') {
        // 缓存地图
        this.$store.commit('addKeepAlive', 'RareTreesBase')
      } else {
        this.$store.commit('removeKeepAlive', 'RareTreesBase')
      }
      this.$router.push({ path: `/rareTreesBase/${value}` })
    }
}
